import * as React from 'react';

import { Footer, GoogleNoIndex, NavMenu, PageTitle } from '../components/shared';

import { Container } from 'react-bootstrap';
import { useScreenInfo } from '../utils';

const PaymentsPage: React.FunctionComponent = () => {
  const screen = useScreenInfo();
  var url = 'https://pay.texastaxprotest.com/texastaxprotestweb/payment/portal.aspx';

  React.useEffect(() => {
    if (typeof location !== `undefined`) {
      const params = new URLSearchParams(location.search);
      const cadid = params.get('cadid') || '';
      const serviceyear = params.get('serviceyear') || '';
      url = url + '?cadid=' + cadid + '&serviceyear=' + serviceyear + '&v=' + Date.now();

      setTimeout(() => {
        location.href = url;
      }, 2000);
    }
  }, []);

  return (
    <>
      <PageTitle />
      <GoogleNoIndex />
      <NavMenu solidColor />
      <Container className="full-page">
        <h1>Loading...</h1>
      </Container>
      <Footer />
    </>
  );
};

export default PaymentsPage;
